/* CSS for the parent div with class "navbar bg-base-100 z-30" */
.navbar {
    background-color: #ffffff;
    z-index: 30;
}

.navbar-start {
}

.btn.btn-ghost.lg\:hidden {
}

/* CSS for the element with class "menu.menu-compact.dropdown-content" */
.menu.menu-compact.dropdown-content {
    margin-top: 0.75rem; /* Adjust the margin-top as needed */
    padding: 0.5rem; /* Adjust the padding as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add your desired box-shadow */
    background-color: #f3f4f6; /* Use your preferred background color */
    border-radius: 0.25rem; /* Adjust the border radius as needed */
    width: 13rem; /* Set the width as needed */
}

.nav-button-icon {
    width: 20px;
    height: 20px;
    fill: currentColor;
}

.nav-dropdown-menu {
    padding: 0.5rem;
    /*border-radius: 0.25rem;*/
    background-color: #fcf9c1;
    z-index: 30;
}
