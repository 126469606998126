/* CSS */
.custom-hero-teams {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2.5rem; /* You can adjust the value as needed */
    font-size: 4rem; /* You can adjust the value as needed */
    font-weight: bold;
    padding-bottom: 1rem; /* You can adjust the value as needed */
    text-align: center;
}
