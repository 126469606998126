@media screen and (max-width: 768px) {
    .video-div {
        /* Make the video responsive */
        width: 100%;
        height: auto;
        margin: 0;
    }
}

.banner {
    text-align: center;
    font-size: 40px;
}
.video-and-comments {
    text-align: center;
}

.dropdownSect {
    display: inline-block;
    margin: auto;
    justify-content: center;
    border: 1px solid black;
    border-radius: 4px;
    /*font-size: 14px;  LOOKING TO SHRINK THE FONT SIZE OF THE TEXT WITHIN THE OPTION TAGS*/
}

.dropdown:disabled {
    opacity: 0.5;
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.sect {
    text-align: center;
}
.video-div {
    margin: 0 auto;
    display: block;
}

.button-tags-container {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    max-width: 75%;
}

.UrlButton {
    display: inline-block;
    background-color: #000000;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 10px;
}
