
.interesting-events-tab {
    color: #333;
    padding: 5px;
}
.banner {
    font-size: 3rem;
    font-weight: 800;
    padding: 20px 0;
    text-align: center;
}

.event-button {
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: #ffffff;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    box-shadow: 0px 2px #f1f1f1;
    cursor: pointer;

    margin-top: 15px;
    margin-bottom: 20px;
    transition: 0.5s ease;
    justify-content: center;
    align-items: center;
}

.button-org {
    display: inline-flex;
    margin: 0 15px;
    align-items: center;
    justify-content: center;
}

.buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding: 10px;
    gap: 10px;
}


/*.interesting-event-container {*/
/*    width: 100%;*/
/*    margin-bottom: 20em;*/
/*    padding: 50px;*/
/*    border-radius: 10px;*/
/*    background-color: #fff;*/
/*    box-shadow: 0 0 10em rgba(0, 0, 0, 0.1);*/
/*}*/

/*.interesting-event-org {*/
/*    display: inline-block;*/
/*    padding: 5px 15px 5px 15px;*/
/*    margin: 20px 20px 0 150px;*/
/*    align-items: center;*/
/*    justify-content: center;*/

/*}*/

.grid-container {
    display: grid;
    justify-content: center;
    margin-top: 1em;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(37.5em, 37.5em));
    overflow-y: auto;

}

.grid-item {
    display: grid;
    border: none;
    padding: 18px;
    border-radius: 4px;
    align-self: start;
    overflow: auto;
}



