body,
html {
    font-family: "Arial", sans-serif;
    color: #333;
}

.form-comment {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin: 20px auto; /* Centering form */
    max-width: 600px; /* Preventing form from spanning too wide */
    width: 100%;
}

.form-comment input[type="text"],
.form-comment textarea {
    width: 100%; /* Ensures input fields take the full width of the form container */
    max-width: 500px; /* Sets max width to prevent from spanning too wide */
    padding: 10px;
    margin: 10px auto; /* Centers the fields within the form */
    border-radius: 5px;
    border: 1px solid #ddd;
    display: block;
    overflow-y: auto; /* Enables vertical scrolling */
}

.form-comment button[type="submit"] {
    background-color: #fdd835; /* Yellow */
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.form-comment button[type="submit"]:hover {
    background-color: #ffc107; /* Darker yellow */
}

/* Responsive Design for the Comment Section */
@media (max-width: 768px) {
    .flex-container {
        flex-direction: column;
    }

    .main-content {
        order: 2; /* Ensures comments are below the video */
    }

    .video-div {
        order: 1;
    }
}

/* Enhancements for mobile readability and interaction */
@media screen and (max-width: 768px) {
    .form-comment {
        width: auto;
        padding: 15px;
        margin: 10px; /* Reduces margin for edge-to-edge design or more space */
    }

    .form-comment input[type="text"],
    .form-comment textarea {
        padding: 8px; /* Adjust padding for easier interaction */
        margin: 8px 0;
    }

    .submission-message {
        margin-left: 0; /* Adjusts positioning for mobile */
        width: 100%; /* Ensures it spans the width of the screen */
        text-align: center;
    }
}

.submission-message {
    color: #4caf50;
    background-color: #dff2bf;
    padding: 10px;
    margin-top: 10px;
    margin-left: 9rem;
    border-radius: 5px;
    position: absolute;
    opacity: 1;
    transition: opacity 3s ease-out; /* Smooth fade out */
}

/* Fade Out Effect */
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOut 3s forwards; /* Use forwards to keep the final state */
}
