/**
    This is the Event css file that modifies the visual aspects of the website. This includes the position, alignments,
    and color of components on the website.
 */
.event, .event-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, .0, 0, 0.75);
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.event:hover, .event-preview:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.75);
}

.title-info {
    text-align: center;
    flex-grow: 1;
    margin: 0;
}

.title-info-preview {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0.5rem;
}

.expanded-info {
    text-align: center;
    margin: 0 1rem;
    font-size: 1rem;
}

.title-date-time-row {
    width: 100%;
    margin: 0.5rem 0;
}

.min-button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
}
.min-button {
    font-size: 1rem;
    background-color: #ffffff;
    color: #606060;
    padding: 8px 16px;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    outline: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}



.min-button:hover {
    background-color: #0a84ff;
}

.interesting-event {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, .0, 0, 0.3);
}

.interesting-video {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
}


.interesting-audio {

}

.description-box1 {
    flex: 1 1 30px;
    width: fit-content;
    padding: 2px;
}

.description-event {
    height: fit-content;
}

.description-expanded{
    border: 1px groove gray;
    padding: 20px;
    text-align: center;

}


