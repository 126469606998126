.posted-comments-section {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.posted-comments-section h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 16px;
}

.posted-comments-section ul {
    list-style-type: none;
    padding: 0;
}

.posted-comment {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment-username {
    margin: 0;
    font-weight: bold;
    color: #000000;
}

.comment-text {
    margin: 5px 0 0 0;
    color: #555;
}
