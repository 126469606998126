.event-button {
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: #ffffff;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    box-shadow: 0px 2px #f1f1f1;
    cursor: pointer;

    margin-top: 20px;
    margin-bottom: 20px;
    transition: 0.5s ease;
    justify-content: center;
    align-items: center;
}

.button-org {
    display: inline;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width: 768px) {
    .event-button {
        width: auto;
    }

    .button-org {
        margin-left: 10px;
        margin-right: 10px;
    }
}
